
$(document).on('turbolinks:load', function() {
  createReadOnlyYamlFields();
  createFormYamlFields();
});

function createFormYamlFields(){
  var myTextArea = document.getElementsByClassName('yaml-field')[0]
  if (myTextArea != undefined) {
    removeDuplicatedCodeMirrors(myTextArea)
    var myCodeMirror = CodeMirror(document.body);
    var defaultValue = myTextArea.value
    var editor = CodeMirror.fromTextArea(myTextArea, {
      value: defaultValue,
      lineNumbers: true,
      mode: "yaml",
      gutters: ["CodeMirror-lint-markers"],
      lint: true
    });
    myTextArea.setAttribute('data-codemirror', 'true');

    editor.on("change", function(cm, n) {
      cm.save()
    });
  }
}

function createReadOnlyYamlFields(){
  var myTextAreas = document.getElementsByClassName('yaml-readonly')
  for (var i = 0, len = myTextAreas.length; i < len; i++) {
    var myTextArea = myTextAreas[i];
    if (myTextArea != undefined) {
      removeDuplicatedCodeMirrors(myTextArea)
      var myCodeMirror = CodeMirror(document.body);
      var editor = CodeMirror.fromTextArea(myTextArea, {
        lineNumbers: true,
        mode: "yaml",
        readOnly: true
      });
      myTextArea.setAttribute('data-codemirror', 'true');
    }
  }
}

function removeDuplicatedCodeMirrors(textArea){
  var is_set = textArea.getAttribute('data-codemirror')
  if (is_set == 'true') {
    var i=0;
    var elem;
    var children = textArea.parentNode.children
    for (var i = 0; i < children.length; i++) {
        if (children[i].classList.contains("CodeMirror")) {
            elem = children[i];
            textArea.parentNode.removeChild(elem) 
        }
    }
  }
}